import { ArrowLeftIcon, ShieldCheckIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { classNames } from "../../../../utils/helpers/misc";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { useToast } from "../../../../components/Toastr/Toastr";
import { months } from "../../../../utils/helpers/misc";

export default function PersonSecuritySubmissionCheckinV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [
    openDeletePersonSecuritySubmissionCheckinModal,
    setOpenDeletePersonSecuritySubmissionCheckinModal,
  ] = useState<boolean>(false);
  const [personSecuritySubmissionCheckin, setPersonSecuritySubmissionCheckin] = useState<any>(null);
  const [faceImageUrl, setFaceImageUrl] = useState<string>("");
  const [idImageUrl, setIdImageUrl] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get personSecuritySubmissionCheckin from database
    const personSecuritySubmissionCheckinId = params.personSecuritySubmissionCheckinId;
    if (personSecuritySubmissionCheckinId) {
      fetchPersonSecuritySubmissionCheckin(personSecuritySubmissionCheckinId);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchPersonSecuritySubmissionCheckin = async (
    personSecuritySubmissionCheckinId: string
  ) => {
    try {
      setLoading(true);
      const res = await apiV3.getPersonSecuritySubmissionCheckinById(
        personSecuritySubmissionCheckinId
      );
      const personSecuritySubmissionCheckin = res.data;
      setPersonSecuritySubmissionCheckin(personSecuritySubmissionCheckin);
      setLoading(false);

      // Get images
      const getImagesPromises = [];

      if (personSecuritySubmissionCheckin.faceImage) {
        const faceImagePromise = apiV3.getPersonSecuritySubmissionCheckinFileById(
          personSecuritySubmissionCheckin.id,
          personSecuritySubmissionCheckin.faceImage
        );
        getImagesPromises.push(faceImagePromise);
      }

      if (personSecuritySubmissionCheckin.idImage) {
        const idImagePromise = apiV3.getPersonSecuritySubmissionCheckinFileById(
          personSecuritySubmissionCheckin.id,
          personSecuritySubmissionCheckin.idImage
        );
        getImagesPromises.push(idImagePromise);
      }

      const [faceImageRes, idImageRes] = await Promise.all(getImagesPromises);

      if (faceImageRes && faceImageRes.data.url) {
        setFaceImageUrl(faceImageRes.data.url);
      }

      if (idImageRes && idImageRes.data.url) {
        setIdImageUrl(idImageRes.data.url);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching personSecuritySubmissionCheckin with id ${personSecuritySubmissionCheckinId}`,
        error
      );
      showErrorToast();
    }
  };

  const deletePersonSecuritySubmissionCheckin = async () => {
    setLoading(true);
    try {
      await apiV3.deletePersonSecuritySubmissionCheckinById(personSecuritySubmissionCheckin.id);
      setOpenDeletePersonSecuritySubmissionCheckinModal(false);
      showSuccessToast("Successfully deleted person security submission check in");
      setLoading(false);
      navigate("/v3/person-audits/checkins");
    } catch (error) {
      console.error(
        `Error occurred while deleting personSecuritySubmissionCheckin with id ${personSecuritySubmissionCheckin.id}`,
        error
      );
      setLoading(false);
      showErrorToast();
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            {personSecuritySubmissionCheckin && !loading ? (
              <>
                <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                  <div className="flex items-center space-x-5">
                    <div>
                      <h1 className="text-2xl font-bold text-gray-900 items-center flex flex-row">
                        <ShieldCheckIcon className="h-8 w-8 mr-1.5" />
                        {personSecuritySubmissionCheckin.firstName +
                          " " +
                          personSecuritySubmissionCheckin.lastName}
                      </h1>
                    </div>
                  </div>
                  <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                      Back
                    </button>
                  </div>
                </div>

                <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                  <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2
                            id="applicant-information-title"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Person Audit Checkin
                          </h2>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Submitted on{" "}
                            {new Date(personSecuritySubmissionCheckin.submittedOn).toLocaleString(
                              "en-AU"
                            )}
                          </p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 flex flex-row">
                          <div className="flex flex-col mr-4">
                            <div>
                              <img
                                className="rounded-md w-64 mb-2"
                                src={
                                  faceImageUrl
                                    ? faceImageUrl
                                    : "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
                                }
                              />
                            </div>
                            <div>
                              <img
                                className="rounded-md w-64"
                                src={
                                  idImageUrl
                                    ? idImageUrl
                                    : "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">ASIC ID</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmissionCheckin.asicId}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Company</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmissionCheckin.company}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  ASIC Expiry Date
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {(personSecuritySubmissionCheckin.asicExpiryDate
                                    ? months[
                                        new Date(
                                          personSecuritySubmissionCheckin.asicExpiryDate
                                        ).getUTCMonth()
                                      ]
                                    : "") +
                                    " " +
                                    new Date(
                                      personSecuritySubmissionCheckin.asicExpiryDate
                                    ).getUTCFullYear()}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Location Name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmissionCheckin.locationName ?? "N/A"}
                                </dd>
                              </div>
                              {personSecuritySubmissionCheckin.vehicleRegistration && (
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-semibold text-gray-500">
                                    Vehicle Registration
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {personSecuritySubmissionCheckin.vehicleRegistration ?? "N/A"}
                                  </dd>
                                </div>
                              )}
                              {personSecuritySubmissionCheckin.submittedLatitude &&
                                personSecuritySubmissionCheckin.submittedLongitude && (
                                  <div className="sm:col-span-1">
                                    <dt className="text-sm font-semibold text-gray-500">
                                      Submitted From
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                      ({personSecuritySubmissionCheckin.submittedLatitude},{" "}
                                      {personSecuritySubmissionCheckin.submittedLongitude})
                                    </dd>
                                  </div>
                                )}

                              {personSecuritySubmissionCheckin.deviceName && (
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-semibold text-gray-500">
                                    Device Name
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {personSecuritySubmissionCheckin.deviceName ?? "N/A"}
                                  </dd>
                                </div>
                              )}
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Note</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmissionCheckin.note}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Face Match</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  <div
                                    className={classNames(
                                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                                      parseFloat(personSecuritySubmissionCheckin.match) > 50
                                        ? "bg-green-100 text-green-800"
                                        : "bg-red-100 text-red-800"
                                    )}
                                  >
                                    {(+parseFloat(personSecuritySubmissionCheckin.match).toFixed(
                                      2
                                    )).toString() + "%"}
                                  </div>
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Submitted By
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmissionCheckin.submittedBy}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Security Tier
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {`${personSecuritySubmissionCheckin.securityTierName ?? "N/A"} ${
                                    personSecuritySubmissionCheckin.securityTierLevel
                                      ? `(Level ${personSecuritySubmissionCheckin.securityTierLevel})`
                                      : ""
                                  }`}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Access Level
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {`${personSecuritySubmissionCheckin.accessLevel ?? "N/A"}`}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </section>
                    {personSecuritySubmissionCheckin.hasOwnProperty("checks") &&
                      personSecuritySubmissionCheckin.checks && (
                        <section aria-labelledby="applicant-information-title">
                          <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h2
                                id="applicant-information-title"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                Checks
                              </h2>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <div>
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                  {JSON.parse(personSecuritySubmissionCheckin.checks).map(
                                    (check: any) => {
                                      return (
                                        <div className="sm:col-span-1">
                                          <dt className="text-sm font-semibold text-gray-500">
                                            {check.name}
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900">
                                            {check.pass ? (
                                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                {check.name === "Driver" ? "Yes" : "Passed"}
                                              </div>
                                            ) : (
                                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                                {check.name === "Driver" ? "No" : "Pending"}
                                              </div>
                                            )}
                                          </dd>
                                        </div>
                                      );
                                    }
                                  )}
                                </dl>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}

                    <section>
                      <div className="bg-white shadow sm:rounded-lg px-4 py-5 sm:px-6">
                        {/* Delete Button */}
                        <button
                          onClick={() => setOpenDeletePersonSecuritySubmissionCheckinModal(true)}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                        >
                          <TrashIcon className="h-5 w-5 mr-1.5" />
                          Delete
                        </button>
                        <DeleteModal
                          open={openDeletePersonSecuritySubmissionCheckinModal}
                          setOpen={setOpenDeletePersonSecuritySubmissionCheckinModal}
                          action={() => deletePersonSecuritySubmissionCheckin()}
                          title="Are you sure you want to delete this checkin?"
                          description="Deleting a checkin is permanent. This action cannot be undone."
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-sm mt-2">Loading...</div>
            )}
          </div>
        </main>
      </div>
      <div className="min-h-full">
        <main className="py-10">{/* Page header */}</main>
      </div>
    </>
  );
}
